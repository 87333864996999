<template>
  <vue-apex-charts type="line" height="350" :options="chartOptions" :series="series" />
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    data: Object,
  },
  setup(props) {
    const series = [
      {
        name: 'Revenues',
        data: props.data.revenues,
      },
      {
        name: 'Expenses',
        data: props.data.expenses,
      },
      {
        name: 'Profits',
        data: props.data.profits,
      },
    ]

    const chartOptions = {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [5, 7, 5],
        curve: 'smooth',
        // curve: 'straight',
        // dashArray: [0, 8, 5],
      },
      // title: {
      //   text: 'Page Statistics',
      //   align: 'left',
      // },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: props.data.categories,
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return '$ ' + Number(val).toFixed(2)
          },
        },
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val
              },
            },
          },
        ],
      },
      grid: {
        borderColor: '#f1f1f1',
      },

      colors: ['#00d4bd', '#FEB019', '#ab7efd'],
    }

    return { apexChatData, series, chartOptions }
  },
}
</script>

<style lang="scss">
.v-application.theme--dark {
  .apexcharts-menu {
    background-color: #312d4b !important;
  }
  .apexcharts-menu-item:hover {
    color: rgba(68, 68, 68, 0.68);
  }
}
</style>
