<template>
  <div class="first-places">
    <div class="place-content">
      <img :src="images.firstImg" alt="First Place" />
      <h4 class="title-1">Type:</h4>
      <span class="values">{{ places[0].type }}</span>
      <h4 class="title-1 mt-2">Reports created:</h4>
      <span class="values">{{ places[0].count }}</span>
    </div>
    <div class="place-content">
      <img :src="images.secondImg" alt="Second Place" />
      <h4 class="title-1">Type:</h4>
      <span class="values">{{ places[1].type }}</span>
      <h4 class="title-1 mt-2">Reports created:</h4>
      <span class="values">{{ places[1].count }}</span>
    </div>
    <div class="place-content">
      <img :src="images.thirdImg" alt="Third Place" />
      <h4 class="title-1">Type:</h4>
      <span class="values">{{ places[2].type }}</span>
      <h4 class="title-1 mt-2">Reports created:</h4>
      <span class="values">{{ places[2].count }}</span>
    </div>
  </div>
</template>

<script>
import firstImg from '@/assets/images/metrics/first.png'
import secondImg from '@/assets/images/metrics/second.png'
import thirdImg from '@/assets/images/metrics/third.png'

export default {
  props: {
    data: Array,
  },
  setup(props) {
    const sortDesc = (a, b) => b.count - a.count

    const places = props.data.sort(sortDesc)

    return {
      places,
      images: {
        firstImg,
        secondImg,
        thirdImg,
      },
    }
  },
}
</script>

<style scoped>
.title-1 {
  text-align: center;
}
.place-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.first-places {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.values {
  text-align: center;
}

@media (max-width: 585px) {
  .first-places {
    justify-content: center;
    gap: 20px;
  }
}
</style>
