<template>
  <vue-apex-charts type="bar" height="350" :options="chartOptions" :series="series" />
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'
import themeConfig from '@themeConfig'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    data: Object,
  },
  setup(props) {
    const $themeColors = themeConfig.themes.light

    const series = [
      {
        data: props.data.data,
      },
    ]

    const chartOptions = {
      chart: {
        toolbar: {
          show: true,
        },
      },
      colors: $themeColors.orange,
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '30%',
          endingShape: 'rounded',
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: props.data.categories,
      },
      yaxis: {
        // opposite: isRtl,
      },
    }

    return { apexChatData, series, chartOptions }
  },
}
</script>
