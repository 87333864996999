<template>
  <vue-apex-charts type="donut" height="350" :options="chartOptions" :series="series" />
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'
import themeConfig from '@themeConfig'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    data: Object,
  },
  setup(props) {
    const $themeColors = themeConfig.themes.light
    const chartColors = {
      donut: {
        series1: '#ffe700',
        series2: '#00d4bd',
        series3: '#826bf8',
        series4: '#2b9bf4',
        series5: '#FFA1A1',
      },
    }
    const total = props.data.data.reduce((previousValue, currentValue) => previousValue + currentValue, 0)
    const series = props.data.data
    const chartOptions = {
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '14px',
        fontFamily: '"Inter", sans-serif',
      },
      colors: [chartColors.donut.series1, chartColors.donut.series5, chartColors.donut.series3, $themeColors.info],
      dataLabels: {
        enabled: true,
        formatter(val) {
          // eslint-disable-next-line radix
          return `${parseInt(val)}%`
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontSize: '2rem',
                fontFamily: '"Inter", sans-serif',
              },
              value: {
                fontSize: '1rem',
                fontFamily: '"Inter", sans-serif',
                formatter(val) {
                  // eslint-disable-next-line radix
                  return `${parseInt(val)}`
                },
              },
            },
          },
        },
      },
      labels: ['SERP Reports', 'Semantic Reports', 'Keyword Research', 'Full Report'],
      responsive: [
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 380,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
        {
          breakpoint: 576,
          options: {
            chart: {
              height: 320,
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      fontSize: '1.5rem',
                    },
                    value: {
                      fontSize: '1rem',
                    },
                    total: {
                      fontSize: '1.5rem',
                    },
                  },
                },
              },
            },
            legend: {
              show: true,
            },
          },
        },
      ],
    }

    return {
      apexChatData,
      series,
      chartOptions,
    }
  },
}
</script>
