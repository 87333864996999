<template>
  <v-container v-if="loading" class="d-flex flex-column">
    <view-loader />
  </v-container>
  <div class="dashboard" v-else>
    <div class="filters">
      <div class="date-filter">
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="start_date_formatted"
              label="Start Date"
              hint="MM/DD/YYYY"
              persistent-hint
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              @blur="date = parseDate(start_date_formatted)"
              v-on="on"
              outlined
              dense
            ></v-text-field>
          </template>
          <v-date-picker v-model="start_date" no-title @input="menu1 = false"></v-date-picker>
        </v-menu>
      </div>
      <div class="date-filter">
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="end_date_formatted"
              label="End Date"
              hint="MM/DD/YYYY"
              persistent-hint
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              @blur="date = parseDate(end_date_formatted)"
              v-on="on"
              outlined
              dense
            ></v-text-field>
          </template>
          <v-date-picker v-model="end_date" no-title @input="menu1 = false"></v-date-picker>
        </v-menu>
      </div>
      <v-btn outlined @click="getData"> Filter </v-btn>
    </div>

    <div>
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <v-card class="card-cost">
            <v-card-text class="card-content-image">
              <div class="contet-text">
                <span class="title-1">Revenues</span>
                <span class="cost">${{ Number(data.revenues).toFixed(2) }}</span>
              </div>
              <div>
                <img class="values-image" :src="images.revenuesImg" alt="Revenues" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card class="card-cost">
            <v-card-text class="card-content-image">
              <div class="contet-text">
                <span class="title-1">Expenses</span>
                <span class="cost">${{ Number(data.expenses).toFixed(2) }}</span>
              </div>
              <div>
                <img class="values-image" :src="images.expensesImg" alt="Revenues" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card class="card-cost">
            <v-card-text class="card-content-image">
              <div class="contet-text">
                <span class="title-1">Profits</span>
                <span class="cost">${{ Number(data.profits).toFixed(2) }}</span>
              </div>
              <div>
                <img class="values-image" :src="images.profitsImg" alt="Revenues" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-row>
        <v-col cols="12" sm="6">
          <v-card class="card-cost">
            <v-card-title>
              <span></span>
            </v-card-title>
            <v-card-subtitle>{{ formatDate2(start_date) }} - {{ formatDate2(end_date) }} </v-card-subtitle>
            <v-card-text class="card-content">
              <apex-chart-sales v-if="data.chart.balance" :data="data.chart.balance" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card class="card-cost">
            <v-card-title>
              <span></span>
            </v-card-title>
            <v-card-subtitle> Current Month </v-card-subtitle>
            <v-card-text class="card-content">
              <apex-chart-balance v-if="data.chart.current_balance" :data="data.chart.current_balance" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card class="card-cost">
            <v-card-title>
              <span></span>
            </v-card-title>
            <v-card-text class="card-content">
              <apex-chart-sales-lines v-if="data.chart.balance" :data="data.chart.balance" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card class="card-cost">
            <v-card-title>
              <span></span>
            </v-card-title>
            <v-card-subtitle> Subscriptions </v-card-subtitle>
            <v-card-text class="card-content">
              <apex-chart-subscriptions v-if="data.chart.payment_history" :data="data.chart.payment_history" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card class="card-cost">
            <v-card-title>
              <span></span>
            </v-card-title>
            <v-card-subtitle> Pay as you go </v-card-subtitle>
            <v-card-text class="card-content">
              <apex-chart-pay-as-you-go v-if="data.chart.payment_history" :data="data.chart.payment_history" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div>
      <h3 class="text-uppercase">Cost by Reports</h3>
      <v-divider class="mt-2 mb-4" />
      <v-row>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  <span>Reports Created</span>
                </v-card-title>
                <v-card-text>
                  <apex-chart-donut-reports :data="data.report_created" />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="card-cost">
                <v-card-text class="card-content">
                  <first-places v-if="data.count" :data="data.count" />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12" md="6">
              <v-card class="card-cost">
                <v-card-text class="card-content">
                  <h3>SERP Reports</h3>
                  <span class="cost">${{ Number(data.cost.serp) }}</span>
                  <span>Aprox.</span>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card class="card-cost">
                <v-card-text class="card-content">
                  <h3>Semantic Report</h3>
                  <span class="cost">${{ Number(data.cost.semantic) }}</span>
                  <span>Aprox.</span>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card class="card-cost">
                <v-card-text class="card-content">
                  <h3>Full Report</h3>
                  <span class="cost">${{ Number(data.cost.full) }}</span>
                  <span>Aprox.</span>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card class="card-cost">
                <v-card-text class="card-content">
                  <h3>Keyword Research</h3>
                  <span>Type: Paid</span>
                  <span class="cost">${{ Number(data.cost.kwr.paid) }}</span>
                  <span>Aprox.</span>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card class="card-cost">
                <v-card-text class="card-content">
                  <h3>Keyword Research</h3>
                  <span>Type: Ranked Keywords</span>
                  <span class="cost">${{ Number(data.cost.kwr.ranked_keywords) }}</span>
                  <span>Aprox.</span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <div>
      <h3 class="text-uppercase">Monthly cost</h3>
      <v-divider class="mt-2 mb-4" />
      <v-row>
        <v-col cols="12" sm="6" md="3" v-for="api in data.api_stables" :key="api.id">
          <v-card class="card-cost">
            <v-card-title>
              <span>{{ api.name }}</span>
            </v-card-title>
            <v-card-text class="card-content">
              <span class="cost">${{ Number(api.cost).toFixed(2) }}</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div>
      <h3 class="text-uppercase">Cost by Request</h3>
      <v-divider class="mt-2 mb-4" />
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <v-card class="card-cost">
            <v-card-title>
              <span>DataForSEO</span>
            </v-card-title>
            <v-card-subtitle> {{ formatDate2(start_date) }} - {{ formatDate2(end_date) }} </v-card-subtitle>
            <v-card-text class="card-content">
              <span class="cost">${{ Number(data.dfs.cost_date).toFixed(6) }}</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card class="card-cost">
            <v-card-title>
              <span>DataForSEO</span>
            </v-card-title>
            <v-card-subtitle> Current Month </v-card-subtitle>
            <v-card-text class="card-content">
              <span class="cost">${{ Number(data.dfs.cost_current_month).toFixed(6) }}</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card class="card-cost">
            <v-card-title>
              <span>Apify</span>
            </v-card-title>
            <v-card-subtitle> {{ formatDate2(start_date) }} - {{ formatDate2(end_date) }} </v-card-subtitle>
            <v-card-text class="card-content">
              <span class="cost">${{ Number(data.apify.cost_date).toFixed(6) }}</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card class="card-cost">
            <v-card-title>
              <span>Apify</span>
            </v-card-title>
            <v-card-subtitle> Current Month </v-card-subtitle>
            <v-card-text class="card-content">
              <span class="cost">${{ Number(data.apify.cost_current_month).toFixed(6) }}</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div>
      <h3 class="text-uppercase">Cost Server Instances</h3>
      <v-divider class="mt-2 mb-4" />
      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>
              <span>Server Instances</span>
            </v-card-title>
            <v-card-text>
              <apex-chart-server-cost v-if="data.servers" :data="data.servers" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12" sm="6" v-for="server in data.servers" :key="server.id">
              <v-card class="card-cost">
                <v-card-title>
                  <span>{{ server.name }}</span>
                </v-card-title>
                <v-card-subtitle>
                  {{ server.purpose }}
                </v-card-subtitle>
                <v-card-text class="card-content">
                  <span class="cost">${{ Number(server.cost).toFixed(2) }}</span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <div>
      <h3 class="text-uppercase">Traffic Sources</h3>
      <v-divider class="mt-2 mb-4" />
      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>
              <span>SERP Reports</span>
            </v-card-title>
            <v-card-text>
              <apex-chart-serp v-if="data.serp_chart" :data="data.serp_chart" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>
              <span>Semantic Reports</span>
            </v-card-title>
            <v-card-text>
              <apex-chart-semantic v-if="data.semantic_chart" :data="data.semantic_chart" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>
              <span>Keyword Research</span>
            </v-card-title>
            <v-card-text>
              <apex-chart-keyword v-if="data.keyword_chart" :data="data.keyword_chart" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>
              <span>Full Reports</span>
            </v-card-title>
            <v-card-text>
              <apex-chart-full-report v-if="data.full_chart" :data="data.full_chart" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div>
      <h3 class="text-uppercase">User Statistics</h3>
      <v-divider class="mt-2 mb-4" />
      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>
              <span>Client Accounts</span>
            </v-card-title>
            <v-card-text>
              <apex-chart-clients v-if="data.clients" :data="data.clients" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>
              <span>New Users</span>
            </v-card-title>
            <v-card-text>
              <apex-chart-users v-if="data.users" :data="data.users" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

import ApexChartSales from '@/components/chart/apex-chart/ApexChartSales.vue'
import ApexChartSalesLines from '@/components/chart/apex-chart/ApexChartSalesLines.vue'
import ApexChartBalance from '@/components/chart/apex-chart/ApexChartBalance.vue'
import ApexChartSubscriptions from '@/components/chart/apex-chart/ApexChartSubscriptions.vue'
import ApexChartPayAsYouGo from '@/components/chart/apex-chart/ApexChartPayAsYouGo.vue'

import ApexChartSerp from '@/components/chart/apex-chart/ApexChartSerp.vue'
import ApexChartSemantic from '@/components/chart/apex-chart/ApexChartSemantic.vue'
import ApexChartKeyword from '@/components/chart/apex-chart/ApexChartKeyword.vue'
import ApexChartFullReport from '@/components/chart/apex-chart/ApexChartFullReport.vue'
import ApexChartDonutReports from '@/components/chart/apex-chart/ApexChartDonutReports.vue'
import ApexChartClients from '@/components/chart/apex-chart/ApexChartClients.vue'
import ApexChartUsers from '@/components/chart/apex-chart/ApexChartUsers.vue'
import ApexChartServerCost from '@/components/chart/apex-chart/ApexChartServerCost.vue'
import ViewLoader from '@/components/spinner/ViewLoader.vue'

//own components
import FirstPlaces from './components/FirstPlaces.vue'

//images
import revenuesImg from '@/assets/images/metrics/revenue.png'
import expensesImg from '@/assets/images/metrics/accounting.png'
import profitsImg from '@/assets/images/metrics/profits.png'

export default {
  components: {
    ApexChartBalance,
    ApexChartSales,
    ApexChartSalesLines,
    ApexChartSubscriptions,
    ApexChartPayAsYouGo,

    ApexChartSerp,
    ApexChartSemantic,
    ApexChartKeyword,
    ApexChartFullReport,
    ApexChartDonutReports,
    ApexChartClients,
    ApexChartUsers,
    ApexChartServerCost,
    ViewLoader,

    FirstPlaces,
  },
  data: vm => {
    return {
      loading: true,
      data: {},

      total_dataforseo: 0,
      total_apify: 0,
      total_ahrefs: 0,
      total_pagespeed: 0,

      cost_serp: 0,
      cost_semantic: 0,
      cost_kw_research: 0,
      cost_full_report: 0,

      start_date: moment().subtract(1, 'year').toISOString(),
      start_date_formatted: moment().subtract(1, 'year').format('MM/DD/YYYY'),
      end_date: moment().toISOString(),
      end_date_formatted: moment().format('MM/DD/YYYY'),
      menu1: false,
      menu2: false,

      images: {
        revenuesImg,
        expensesImg,
        profitsImg,
      },
    }
  },
  computed: {
    ...mapGetters({
      hasUserRole: 'auth/hasUserRole',
      hasUserPermission: 'auth/hasUserPermission',
    }),
  },
  watch: {
    start_date(val) {
      this.start_date_formatted = this.formatDate(val)
    },
    end_date(val) {
      this.end_date_formatted = this.formatDate(val)
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    ...mapActions({
      getDashboard: 'super_admin/dashboard',
    }),
    async getData() {
      this.loading = true
      await this.getDashboard(
        new URLSearchParams({
          start_date: this.start_date,
          end_date: this.end_date,
        }).toString(),
      )
        .then(resp => {
          if (resp && resp.data) {
            this.data = resp.data
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    formatDate2(date) {
      return moment(date).format('MM/DD/YYYY')
    },
  },
}
</script>

<style lang="scss" scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.line-row {
  display: flex;
  flex-direction: row;
}

.line-row .wrap {
  flex-wrap: wrap;
}

.filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}

.date-filter {
  max-width: 400px;
}

.card-cost {
  padding: 5px;
  min-height: 120px;
}

.card-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.cost {
  font-size: 28px;
}

.card-content-image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
}
.card-content-image .contet-text {
  display: flex;
  flex-direction: column;
}

.card-content-image .contet-text .title-1 {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
}
.values-image {
  width: 100px;
}
</style>
