<template>
  <vue-apex-charts type="bar" height="350" :options="chartOptions" :series="series" />
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    data: Object,
  },
  setup(props) {
    const series = [
      {
        name: 'Keyword Research',
        data: props.data.data,
      },
    ]

    const chartOptions = {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },

      xaxis: {
        categories: props.data.categories,
        position: 'top',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val
          },
        },
      },
      colors: ['#2b9bf4'],
    }

    return { apexChatData, series, chartOptions }
  },
}
</script>

<style lang="scss">
.v-application.theme--dark {
  .apexcharts-menu {
    background-color: #312d4b !important;
  }
  .apexcharts-menu-item:hover {
    color: rgba(68, 68, 68, 0.68);
  }
}
</style>
