<template>
  <vue-apex-charts type="donut" height="350" :options="chartOptions" :series="series" />
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'
import themeConfig from '@themeConfig'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    data: Array,
  },
  setup(props) {
    const distinct = (value, index, self) => {
      return self.indexOf(value) === index
    }

    let categories = []
    categories = props.data.map(cat => cat.purpose)
    categories = categories.filter(distinct)

    let series = []
    categories.forEach(cat => {
      let value = props.data.reduce((sum, current) => {
        if (cat == current.purpose) {
          return Number(sum) + Number(current.cost)
        }
        return sum
      }, 0)
      series.push(value)
    })

    const chartOptions = {
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '14px',
        fontFamily: '"Inter", sans-serif',
      },
      dataLabels: {
        enabled: true,
        formatter(val) {
          // eslint-disable-next-line radix
          return `${parseInt(val)}%`
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontSize: '2rem',
                fontFamily: '"Inter", sans-serif',
              },
              value: {
                fontSize: '1rem',
                fontFamily: '"Inter", sans-serif',
                formatter(val) {
                  // eslint-disable-next-line radix
                  return `$${parseInt(val)}`
                },
              },
            },
          },
        },
      },
      labels: categories,
      responsive: [
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 380,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
        {
          breakpoint: 576,
          options: {
            chart: {
              height: 320,
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      fontSize: '1.5rem',
                    },
                    value: {
                      fontSize: '1rem',
                    },
                    total: {
                      fontSize: '1.5rem',
                    },
                  },
                },
              },
            },
            legend: {
              show: true,
            },
          },
        },
      ],
    }

    return {
      apexChatData,
      series,
      chartOptions,
    }
  },
}
</script>
