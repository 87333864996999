<template>
  <vue-apex-charts type="bar" height="350" :options="chartOptions" :series="series" />
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    data: Object,
  },
  setup(props) {
    const series = [
      {
        name: 'Revenues',
        data: props.data.revenues,
      },
      {
        name: 'Expenses',
        data: props.data.expenses,
      },
      {
        name: 'Profits',
        data: props.data.profits,
      },
    ]

    const chartOptions = {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '13px',
                fontWeight: 900,
              },
            },
          },
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },

      xaxis: {
        categories: props.data.categories,
        labels: {
          formatter: function (val) {
            return '$ ' + Number(val).toFixed(2)
          },
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40,
      },

      colors: ['#00d4bd', '#FEB019', '#ab7efd'],
    }

    return { apexChatData, series, chartOptions }
  },
}
</script>

<style lang="scss">
.v-application.theme--dark {
  .apexcharts-menu {
    background-color: #312d4b !important;
  }
  .apexcharts-menu-item:hover {
    color: rgba(68, 68, 68, 0.68);
  }
}
</style>
